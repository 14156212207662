body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0.5px;
    padding: 0;
  }
  
  #LoginInButton {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
  }
  #LoginInButton button {
    background-color: #212529 !important;
    color: white !important;
    font-size: 20px !important;
    border-radius: 10px !important;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
    width: 150px;
  }
  #signOutButton {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #signOutButton button {
    background-color: #212529 !important;
    color: white !important;
    font-size: 20px !important;
    border-radius: 100px !important;
    padding: 20px !important;
    margin: 0 !important;
    border: none !important;
    width: 150px;
    display: none !important;
  }

  #signOutButton button:hover{
    background-color: #3d3d3d !important;
  }
  
  #LoginInButton button div {
    border-radius: 8px !important;
  }
  
  #facebookButton button {
    background-color: #212529 !important;
    color: white !important;
    font-size: 20px !important;
    border-radius: 10px !important;
  
    margin-top: 20px !important;
    text-align: center;
    width: 150px;
    border: none !important;
    display: inline-flex !important;
    align-items: center !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
      rgba(0, 0, 0, 0.24) 0px 0px 1px 0px !important;
    font-weight: 500 !important;
    font-family: Roboto, sans-serif !important;
  }
  #facebookButton button img {
    margin-right: 10px !important;
    background: rgb(255, 255, 255) !important;
    padding: 10px !important;
    border-radius: 8px !important;
    height: 9vh;
  }
  #facebookButton button span {
    /* padding: 10px 10px 10px 0px !important; */
    font-weight: 500 !important;
  }
  
  button span {
    margin-left: 20px !important;
  }
  
  .kep-login-facebook {
    text-align: center;
    background-color: #3b2424 !important;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  h1,
  h2,
  h3 {
    font-family: "Helvetica Neue";
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  
  .btn,
  .btn-dark,
  .btn-check:focus + .btn-dark,
  .btn-dark:focus,
  .btn-check:focus + .btn,
  .btn:focus {
    border: none;
    box-shadow: none;
  }
  
  .btn-dark:hover {
    background: #212529;
  }
  
  .bg-primary,
  .btn-light {
    letter-spacing: 0.7px;
  }
  
  
  .custom-loader {
      width: 50px;
      height: 50px;
      display: grid;
  }
  .custom-loader::before,
  .custom-loader::after {    
      content:"";
      grid-area: 1/1;
      --c: radial-gradient(farthest-side,#5A63A0 92%,#0000);
      background: 
        var(--c) 50%  0, 
        var(--c) 50%  100%, 
        var(--c) 100% 50%, 
        var(--c) 0    50%;
      background-size: 12px 12px;
      background-repeat: no-repeat;
      animation: s2 1s infinite;
  }
  .custom-loader::before {
    margin:4px;
    filter:hue-rotate(45deg);
    background-size: 8px 8px;
    animation-timing-function: linear
  }
  
  @keyframes s2{ 
    100%{transform: rotate(.5turn)}
  }

  /* Add this CSS to your project */
.hide-dropdown-arrow .dropdown-toggle::after {
  display: none !important;
}
@media screen and (max-width:700px){
  html{
      font-size:12px;
  }
}