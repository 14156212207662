.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body{
  position: relative;
}
:root{
  --home-color:brightness(0) saturate(100%) invert(86%) sepia(13%) saturate(14%) hue-rotate(30deg) brightness(83%) contrast(87%);
  --dot-color:grey;
}
.dark{
--background-color:#000;
--text-color:#fff;
}
.light{
--background-color:#fff;
--text-color:#000;
}

section {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  min-height: 100vh; /* Ensures the section takes the full viewport height */
  padding-top: 20px; /* Adjust as needed for spacing at the top */
  width: 100%;
}
main{
  background-color:var(--background-color) !important;
  color:var(--text-color) !important;
}
nav{
  position: absolute;
  top:0;
  left:0;
  display: flex;
  gap: 15px;
}
.home-icon{
  width:50px;
  filter: var(--home-color);
}
article{
  color:var(--text-color);
  margin:15px;
  padding:5px;
}