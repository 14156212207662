
main{
    padding:46px 15px;
    color:#fff;

}
header{
    text-align: center;
}


.form-control{
    margin:15px 0;
}
.answer{
    color:rgb(77, 248, 77);
}
@media print{
    article{
        color:#000;
        border-bottom:2px solid black;
    }
    .answer{
        display: none;
    }
    aside{
        display: none;
    }
}
